@font-face {
  font-family: 'Maax';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./assets/fonts/Maax-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Maax';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url('./assets/fonts/Maax-Bold.woff2') format('woff2');
}

p{
  font-family:  "Maax";
}