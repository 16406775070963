.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fade-in-image {
  animation: fadeIn 10s;
  -webkit-animation: fadeIn 10s;
  -moz-animation: fadeIn 10s;
  -o-animation: fadeIn 10s;
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
#react-chrono-timeline{
  justify-content: flex-start;
}


@media screen and (min-width: 1092px) {
  #react-chrono-timeline{
    justify-content: flex-start;
  }
  
  .timeline-card-content > header > p{
    font-size: 2.0em;
  }
  
  .timeline-card-content > div > p{
    font-size: 2.0em;
    line-height: 1.5;
  }
}

.arrow {
  border: solid white;
  border-width: 0 10px 10px 0;
  display: inline-block;
  padding: 15px;
  border-radius: 5px;
  position: absolute;
  right: 7%;
  bottom: 34%;
}


html, 
body {
    margin: 0;
    height:100%;
 
}


.card-description {
  margin: 12px  !important
}